import { Link } from 'gatsby'
import React, { useMemo } from 'react'

import type { Store } from './SearchStore'

interface Props {
  store?: Store
}

export const SearchStoreNavigationProductPage = ({ store }: Props) => {
  const storeAbbreviation = useMemo(() => {
    if (!store || store?.loja?.toLowerCase().includes('quiosque')) {
      return null
    }

    const match = store?.loja?.match(/\((.*?)\)/)

    return match?.[1] ? match[1].toLocaleLowerCase() : null
  }, [store])

  if (!storeAbbreviation) {
    return <></>
  }

  return (
    <Link to={`/loja/lojavivara${storeAbbreviation}`}>
      <button className="navigation-produtct-page__button">
        ver produtos desta loja
      </button>
    </Link>
  )
}
