import React, { useEffect, useRef, useState } from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import SearchStore from 'src/components/common/searchStore/SearchStore'
import { graphql } from 'gatsby'
import { useSession } from 'src/sdk/session'
import { usePageViewEvent } from 'src/sdk/analytics/hooks/usePageViewEvent'
import { useCampaignContext } from 'src/contexts/campaign-context'

function Page() {
  const { person } = useSession()
  const [didMount, setDidMount] = useState(false)
  const { sendPageViewEvent } = usePageViewEvent('Nossas-Lojas', person)
  const { setCampaignPage } = useCampaignContext()

  setCampaignPage(false)

  const timerRef = useRef<NodeJS.Timeout | undefined>()

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      if (!didMount) {
        sendPageViewEvent().catch((err) =>
          console.error(`Error na page view event ${err}`)
        )
        setDidMount(true)
      }
    }, 500)
  }, [person])

  useEffect(() => {
    window.insider_object = window.insider_object || {}
    window.insider_object.page = {
      type: 'Information',
      originalType: 'Information',
    }
  }, [])

  return (
    <>
      <GatsbySeo
        title="Nossas Lojas"
        description="Nossas Lojas Page"
        language="pt-BR"
      />
      <section className="page__section">
        <SearchStore />
      </section>
    </>
  )
}

export const query = graphql`
  query StoresPageQuery {
    cmsGlobalComponents {
      sections {
        name
        data
      }
    }
  }
`

export default Page
